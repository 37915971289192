<template>
  <section>
    <b-overlay :show="loadingRekap">
      <div >
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <h3>History</h3>
            </div>
            <div class="d-flex align-items-center">
              <h3>
                <b-button variant="gradient-danger" class="btn-icon" @click.prevent="reloadPage">
                  <feather-icon icon="RefreshCwIcon" />
                </b-button>
              </h3>
            </div>
          </div>
        </b-card>
        <b-overlay :show="loading">
          <b-tabs fill content-class="pt-1">
            <b-tab :title="item.name" v-for="(item, i) in paketCategories" :key="i" @click="setPaket(item)">
              <!-- card rekap -->
              <b-row>
                  <b-col lg="4" sm="6">
                    <statistic-card-horizontal icon="CheckIcon" :statistic="rekap.tryout_diikuti" color="success" statistic-title="Tryout Diikuti" />
                  </b-col>
                  <b-col lg="4" sm="6">
                    <statistic-card-horizontal icon="FileTextIcon" :statistic="rekap.tryout_dikerjakan" statistic-title="Tryout Dikerjakan" />
                  </b-col>
                  <b-col lg="4" sm="6">
                    <statistic-card-horizontal icon="ActivityIcon" color="info" :statistic="rekap.nilai_rata" statistic-title="Nilai Rata-Rata" />
                  </b-col>
                </b-row>
                <!-- / -->
              <b-card>
                <div class="table-responsive">
                  <table 
                    v-for="(tableR, i_table) in tableRaport"
                    :key="i_table"
                    class="table table-bordered mb-2"
                  >
                    <tr>
                      <th>Tryout</th>
                      <th v-for="(header, i) in tableR.header" :key="i">{{ header.toUpperCase() }}</th>
                    </tr>
                    <tr
                      v-for="(raport) in tableR.raports" 
                      :key="`raport_${raport.paket_id}`"
                    >
                      <td>{{ raport.name }}</td>
                      <td v-for="(data, i) in raport.scores" :key="i">{{ data?.nilai }}</td>
                    </tr>
                  </table>
                </div>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import { BOverlay, BCard, BCardText, BCardBody, BRow, BCol, BTable, BTabs, BTab, BButton } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import Blank from "./components/Blank";
export default {
  data() {
    return {
      loading: false,
      loadingRekap: false,
      categoryPaketId: null,
      raports: [],
      headers: [],
      items: [],
      dataPaket: [],
      rekap: {
        tryout_diikuti: 0,
        tryout_dikerjakan: 0,
        nilai_rata: 0
      },
      paketCategories: [],
      tableRaport: []
    };
  },
  components: {
    BOverlay,
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BTabs,
    BTab,
    BButton,
    StatisticCardHorizontal,
    Blank,
  },
  watch: {
    categoryPaketId(val) {
      this.getDataRaport();
      this.getDataRekap();
    },
  },
  methods: {
    reloadPage() {window.location.reload()},
    async getPaketCategory() {
      try {
        const response = await this.$store.dispatch('paketTryout/indexKategoriPaket', {});
        this.paketCategories = response.data?.data
        if(this.paketCategories.length > 0) this.categoryPaketId = this.paketCategories[0]?.id
      }
      catch(e) {
        this.displayError(e)
        return false
      }
    },
    setPaket(item) {
      this.categoryPaketId = item.id;
    },
    getDataPaket() {
      this.loading = true;
      let params = {};
      this.$store
        .dispatch("tryout/myPaket", params)
        .then((res) => {
          this.loading = false;
          this.dataPaket = res.data.data;
          this.categoryPaketId = this.dataPaket[0].id;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getDataRekap() {
      this.loadingRekap = true;
      let params = {
        category_paket_id: this.categoryPaketId
      };
      this.$store
        .dispatch("raport/rekap", params)
        .then((res) => {
          this.loadingRekap = false;
          this.rekap = res.data;
        })
        .catch((err) => {
          this.loadingRekap = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getDataRaport() {
      this.loading = true;
      let params = {
        kategori_paket_id: this.categoryPaketId,
      };
      this.$store
        .dispatch("raport/list", params)
        .then((res) => {
          this.loading = false;
          this.raports = res.data?.data;
          this.tableRaport = []
          
          // create header
          if(this.raports.length > 0) {
            // jadikan data pertama sebagai acuan header
            const firstPaket = this.raports[0]
            const firstHeader = firstPaket.scores.map(score => score.ujian_shortname.toLowerCase())

            // jika ada header berbeda diantara paket yang ada, dipisahkan
            let isMatch = true
            this.raports.forEach(raport => {
              const paketHeader = raport.scores.map(score => score.ujian_shortname.toLowerCase())
              
              // check match header and paketHeader
              isMatch = firstHeader.toString() == paketHeader.toString()
            })

            // jika ada header yang beda
            if(!isMatch) {
              this.raports.forEach(raport => {
                const paketId = raport.paket_id
                const paketHeader = raport.scores.map(score => score.ujian_shortname.toLowerCase())
                this.tableRaport.push({
                  header: paketHeader,
                  raports: this.raports.filter(item => item.paket_id == paketId)
                })
              })
            }
            else {
              this.tableRaport = [
                {
                  header: firstHeader,
                  raports: this.raports
                }
              ]
            }
          }

        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  async created() {
    this.loadingRekap = true
    await this.getPaketCategory()
    this.loadingRekap = false
  },
};
</script>

<style></style>
